<template>
  <div>
    <c-card title="법규별 규제사항" class="cardClassDetailForm">
      <template slot="card-button">
        <q-btn-group outline >
          <c-btn
            v-show="editable&&!disabled"
            label="검증"
            icon="calculate"
            @btnClicked="verification" />
          <c-btn
            v-show="editable&&!disabled"
            :url="saveUrl"
            :isSubmit="isSave"
            :param="saveData"
            mappingType="PUT"
            label="저장"
            icon="save"
            @beforeAction="saveInfo"
            @btnCallback="saveCallback" />
        </q-btn-group>
      </template>
      <template slot="card-detail">
        <div class="col-12 divchklaw" v-for="(item, idx) in review.bills" :key="idx">
          <div class="lawspan">{{item.regulationBillName}}</div>
          <q-checkbox
            v-for="(regul, _idx) in item.reguls" :key="_idx"
            dense
            class="customqcbox"
            color="orange-custom"
            true-value="Y"
            false-value="N"
            :disable="!editable||disabled"
            :label="regul.regulatedItemName"
            v-model="regul.val"
          />
        </div>
        <q-inner-loading :showing="loading">
          <q-spinner-cube size="100px" color="primary" />
        </q-inner-loading>
      </template>
    </c-card>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>
<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'chem-review-law',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        mdmChemReviewId: '',
      }),
    },
    review: {
      type: Object,
      default: () => ({
        mdmChemReviewId: '',  // 사전검토 일련번호
        reviewRequestDate: '',  // 검토요청일
        plantCds: '',  // 사업장코드
        materialCd: '',  // 자재코드
        materialName: '',  // 자재명(KOR)
        materialNameEn: '',  // 자재명(ENG)
        deliveryVendorCd: null,  // 납품업체 코드
        deliveryVendorName: '',  // 납품업체명
        mfgVendorCd: null,  // 제조업체 코드
        mfgVendorName: '',  // 제조업체명
        mdmChemReviewStepCd: null,  // 사전검토 진행상태
        propertiesStateCd: null,  // 성상-공통코드
        fugacity: '',  // 비산성(고체)
        boilPoint: '',  // 끊는점(액체,기체)
        meltingPoint: '',  // 녹는점
        moleWeight: '',  // 분자량
        usageCd: null,  // 용도-회사별 공통코드
        usageEtc: '',  // 용도 기타
        hsNo: '',  // HS번호
        specificGravity: '',  // 비중
        casNo: '',  // CAS No.
        serialNo: '',  // 고유번호(유독물번호or고시번호)
        keNo: '',  // KE 번호
        psmFlag: 'N',  // PSM 대상 여부
        mixFlag: 'N',  // 물질구성(단일 N/혼합 Y)
        licensingFlag: 'N',  // 인허가대상 물질 여부 Y/N
        dangerFlag: 'N',  // 위험물 여부Y/N
        makeFlag: 'N',  // 제품분류(제조)
        impFlag: 'N',  // 제품분류(수입)
        buyFlag: 'N',  // 제품분류(구매)
        usingFlag: 'N',  // 제품분류(사용)
        expFlag: 'N',  // 제품분류(수출)
        salesFlag: 'N',  // 제품분류(판매)
        toxicPoisonFlag: 'N',  // 유해화학물질포함여부(유독물질)
        toxicPermitFlag: 'N',  // 유해화학물질포함여부(허가물질)
        toxicLimitFlag: 'N',  // 유해화학물질포함여부(제한물질)
        toxicProhibitFlag: 'N',  // 유해화학물질포함여부(금지물질)
        remarks: '',  // 비고
        returnRemark: '',  // 반려사유
        regUserId: '',  // 등록자 ID
        chgUserId: '',  // 수정자 ID
        subs: [],
        prodReguls: [],
        bills: [],
        checks: [],
      }),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      saveData: {
        mdmChemReviewId: '',
        prodReguls: [],
      },
      isSave: false,
      editable: true,
      listUrl: '',
      calUrl: '',
      saveUrl: '',
      popupOptions: {
        isFull: false,
        width: '100%',
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.mdm.mam.review.regul.list.url;
      this.calUrl = selectConfig.mdm.mam.review.regul.calcul.url;
      this.saveUrl = transactionConfig.mdm.mam.review.regul.save.url;
      // code setting
      // list setting
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        mdmChemReviewId: this.popupParam.mdmChemReviewId
      };
      this.$http.request((_result) => {
        this.$set(this.review, 'prodReguls', _result.data)

        this.review.bills = [];
        if (this.review.prodReguls && this.review.prodReguls.length > 0) {
          this.review.bills = this.$_.map(this.review.prodReguls, (value) => {
            return {
              regulationBillCd: value['regulationBillCd'],
              regulationBillName: value['regulationBillName'],
              reguls: [],
            }
          });
          this.review.bills = this.$_.uniqBy(this.review.bills, (item) => {
            return item.regulationBillCd
          })
          this.$_.forEach(this.review.bills, bill => {
            this.$set(bill, 'reguls', this.$_.filter(this.review.prodReguls, { regulationBillCd: bill.regulationBillCd }))
          })
        }
      },);
    },
    verification() {
      this.popupOptions.title = '규제검증';
      this.popupOptions.param = {
        mdmChemReviewId: this.popupParam.mdmChemReviewId,
      };
      this.popupOptions.target = () => import(`${'./chemReviewVerification.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.isFull = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.$emit('getDetailInfo');
    },
    calculate() {
      // this.$set(this.review, 'bills', []);
      this.loading = true;
      this.$http.url = this.calUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        mdmChemReviewId: this.popupParam.mdmChemReviewId
      };
      this.$http.request((_result) => {
        this.$set(this.review, 'prodReguls', _result.data)

        // 
        let _bills = [];
        if (this.review.prodReguls && this.review.prodReguls.length > 0) {
          _bills = this.$_.map(this.review.prodReguls, (value) => {
            return {
              regulationBillCd: value['regulationBillCd'],
              regulationBillName: value['regulationBillName'],
              reguls: [],
            }
          });
          _bills = this.$_.uniqBy(_bills, (item) => {
            return item.regulationBillCd
          })
          this.$_.forEach(_bills, bill => {
            this.$set(bill, 'reguls', this.$_.filter(this.review.prodReguls, { regulationBillCd: bill.regulationBillCd }))
          })
          this.$set(this.review, 'bills', _bills);
          setTimeout(() => {
            this.loading = false;
          }, 500);
        }
      }, () => {
        this.loading = false;
      });
    },
    saveInfo() {
      window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '저장하시겠습니까?',
        
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$set(this.saveData, 'mdmChemReviewId', this.popupParam.mdmChemReviewId)
          this.$set(this.saveData, 'prodReguls', this.$_.filter(this.review.prodReguls, { val: 'Y' }))

          this.isSave = !this.isSave;
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    saveCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      Object.assign(this.$data.saveData, this.$options.data().saveData);
      this.getList();
    },
  }
};
</script>
<style>
  .lawspan {
    color: #0072c6 !important;
    font-size: 13px !important;
    font-weight: 600;
  }
  .divchklaw {
    margin-bottom: 10px;
  }
</style>
